<template>
  <Card style="width: 30em">
    <template #header>
      <img alt="404" src="images/hood2.jpg" />
    </template>
    <template #title>
      Ошибка 404
    </template>
    <template #subtitle>
      По этому адресу ничего нет
    </template>
    <template #content>
      Мы не нашли ничего по Вашему запросу. Но мы работает над этим. А пока
      пожалуйста вернитесь в основной раздел
    </template>
    <template #footer>
      <Button
        label="Врнуться"
        @click="$router.push('/')"
        class="p-button-primary"
      />
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
  components: {
    Card, Button
  }
}
</script>

<style></style>
